import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { complement, filter, propEq, map } from 'ramda';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import ComparisonGrid from '@greenberry/salal/components/ComparisonGrid';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import getLatestYear from '../../utils/getLatestYear';

import ComparisonLayout from '../../components/ComparisonLayout';
import {
  withIndicators,
  selectedForComparison,
  previewState,
} from '../../state/selectedForComparison';

const formatPartnerships = map(
  ({
    externalId,
    name,
    regionSource,
    street,
    houseNumber,
    houseNumberAddition,
    zipcode,
    city,
    municipalities,
    schools,
    indicators,
    datoAddress,
    datoAddressHousenumber,
    datoAddressAddition,
    datoZipcode,
    datoCity,
    datoSchools,
    datoMunicipalities,
  }) => {
    const latestYear = getLatestYear(indicators.studentCount);
    const studentCount = Object.keys(latestYear).reduce(
      (acc, curr) => (acc += latestYear[curr]),
      0
    );
    return {
      externalId,
      name,
      regionSource,
      contactInfo: {
        address: [
          `${datoAddress || street} ${datoAddressHousenumber || houseNumber} ${
            datoAddressHousenumber ? datoAddressAddition : houseNumberAddition
          }, ${datoZipcode || zipcode} ${datoCity || city}`,
        ],
      },
      data: {
        students: `${studentCount} leerlingen`,
        municipalities: `${datoMunicipalities || municipalities} gemeenten`,
        schools: `${datoSchools || schools} scholen`,
      },
    };
  }
);
const WithData = () => {
  const preview = useRecoilValue(previewState);
  const partnerships = useRecoilValueLoadable(withIndicators);

  const setSelectedForComparison = useSetRecoilState(selectedForComparison);
  if (partnerships.state === 'loading') {
    return (
      <ComparisonGrid
        partnerships={preview.map(item => ({
          contactInfo: {
            address: ['Laden…'],
          },
          data: {
            students: 'Laden…',
            municipalities: 'Laden…',
            schools: 'Laden…',
          },
          ...item,
        }))}
      />
    );
  }

  const formatted = formatPartnerships(partnerships.contents);

  return (
    <ComparisonGrid
      partnerships={formatted}
      onPartnershipRemove={item => {
        setSelectedForComparison(
          filter(complement(propEq('externalId', item.externalId)))
        );
      }}
    />
  );
};

const ComparisonIndex = ({ data: { copy }, ...props }) => {
  return (
    <ComparisonLayout seoMetaTags={copy.seoMetaTags}>
      <Section size="2">
        <Introduction title={copy.title} text={copy.intro} />
      </Section>
      <Section size="2">
        <WithData {...props} />
      </Section>
    </ComparisonLayout>
  );
};

ComparisonIndex.propTypes = {
  data: PropTypes.shape({
    copy: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({
        tags: PropTypes.array,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ComparisonIndex;

export const query = graphql`
  query getGeneralInformation {
    copy: datoCmsComparisonGeneralInformation {
      title
      intro
      seoMetaTags {
        tags
      }
    }
  }
`;
